import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import React from 'react'
import MarqueeFast from 'react-fast-marquee'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'components/Accordion'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Hero2 from 'components/Hero2'
import Image from 'components/Image'
import Layout from 'components/Layout'
import MediaObject from 'components/MediaObject'
import Module1s from 'components/Module1s'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import enterprise from 'libs/enterprise'
import styles from '../Partnerships.module.scss'
import { ReactComponent as PersonHeartSvg } from '../images/value-props/person-heart.svg'
import { ReactComponent as PiggyBankSvg } from '../images/value-props/piggy-bank.svg'
import { ReactComponent as StarSvg } from '../images/value-props/star.svg'

export default function PartnershipsEventsRiseQualipalooza() {
  useEventPageVisited('Event – Rise Qualipalooza')

  const product = [
    {
      title: 'Medicare Advantage',
      copy: 'Get more from your supplemental benefits through an engaging, personalized approach to exercise and physical activity for older adults. Innovative Medicare Advantage plans rely on Bold to help reduce falls, increase quality scores, and support an excellent member experience.',
      id: React.useId(),
      image: (
        <StaticImage
          src="../images/screenshots/dashboard.png"
          alt="Screenshot of the Bold dashboard"
          options={{
            quality: 100,
          }}
        />
      ),
    },
    {
      title: 'Duals',
      copy: 'Bold provides equitable access to personalized exercise programs that increase physical activity levels and help manage chronic conditions, along with educational resources and compassionate support.',
      id: React.useId(),
      image: (
        <StaticImage
          src="../images/screenshots/assessments.png"
          alt="Screenshot of Bold assessments"
          options={{
            quality: 100,
          }}
        />
      ),
    },
  ]

  const [productActive, setProductActive] = React.useState(product[0].title)

  const valueProps = [
    {
      title: 'Improve outcomes',
      copy: (
        <>
          Falls impact more than 1 in 4 Americans over 65 each year; Bold’s platform reduces falls
          by &gt;40%
          <br />
          <Text size="small">(Journal of Medical Internet Research; CDC)</Text>
        </>
      ),
      icon: PiggyBankSvg,
    },
    {
      title: 'Increase quality',
      copy: 'Influence HOS measures including reduction of fall risk, osteoporosis management among women with a fracture, and increased physical activity',
      icon: StarSvg,
    },
    {
      title: 'Retain members',
      copy: 'Beloved by members, with a 69 NPS and 8 in 10 saying they are more likely to retain their plan because of Bold',
      icon: PersonHeartSvg,
    },
  ]

  const productActiveItem = product.find((item) => item.title === productActive)

  const meetingUrl = 'https://calendly.com/d/cp5g-5tc-stq/qualipalooza-bold-demo'
  const name = 'Rise Qualipalooza'

  const buttonProps = {
    size: 'large',
  }

  const titleProps = {
    align: 'center',
    size: 'large',
  }

  return (
    <Layout className={classNames('PartnershipsEventsRiseQualipalooza', styles.this)}>
      <Head
        title={`Bold at ${name}`}
        description="Our health plan partnerships save money and improve lives. Bold’s programs achieve clinical outcomes most relevant to Medicare plans."
      />
      <Row size="xxxlarge">
        <Hero2
          title="Reduce falls, increase activity, improve satisfaction"
          subtext="Meet with our team at Rise Qualipalooza to learn how our clinical exercise platform can support your plan’s cost and quality objectives."
          button={
            <Button to={meetingUrl} color="yellow" size="xlarge">
              Book time with our team
            </Button>
          }
          image={
            <StaticImage
              src="../images/header.jpg"
              alt=""
              layout="fullWidth"
              options={{
                quality: 100,
              }}
            />
          }
          bottom={
            <>
              <Row size="large">
                <MarqueeFast gradient={false} speed={10} className={styles.partners}>
                  {_.times(2, () =>
                    Object.keys(enterprise.PARTNERS)
                      .filter(
                        (item) =>
                          ![
                            enterprise.DEMO_CLINICAL_KEY,
                            enterprise.GROUPER_KEY,
                            enterprise.HUMPBACK_KEY,
                            enterprise.OTTER_KEY,
                            enterprise.SEAL_KEY,
                          ].includes(item)
                      )
                      .map((item) => (
                        <Image
                          // TODO: something better…
                          src={
                            _.startsWith(item, enterprise.CUTTLEFISH_KEY)
                              ? `/enterprise/${item}.svg`
                              : enterprise.getLogo(item)
                          }
                          alt={enterprise.getName(item)}
                          key={item}
                          className={styles['partners--logo']}
                        />
                      ))
                  )}
                </MarqueeFast>
              </Row>
              <Container size="xxlarge">
                <Row size="large">
                  <Container flush size="large">
                    <Box align="center" color="white" size="large">
                      <Container flush>
                        <Title
                          {...titleProps}
                          subtext="Bold’s dynamic exercise programs support members of all activity levels and reduce meaningful health spend and hospitalizations."
                          flush>
                          A preventive, engaging approach for your diverse membership
                        </Title>
                      </Container>
                    </Box>
                  </Container>
                </Row>
                <Module1s data={valueProps} />
              </Container>
            </>
          }
        />
      </Row>
      <View flush className={styles.view}>
        <Row size="xxxlarge">
          <Container size="xxlarge">
            <Row size="xxxlarge">
              <Container flush>
                <Title
                  {...titleProps}
                  subtext="Bold’s platform leverages cutting-edge behavioral science to engage members and promote durable habits, while providing an affirming, accessible experience that delivers leading outcomes and cost savings.">
                  The only purpose-built healthy aging platform for Medicare
                </Title>
              </Container>
              <MediaObject
                figure={
                  <Accordion
                    preExpanded={[`${product[0].title}`]}
                    className={styles['product-accordion']}
                    onChange={(activeUuids) => setProductActive(activeUuids[0])}>
                    {product.map((item) => (
                      <AccordionItem key={item.title} uuid={item.id}>
                        <AccordionItemTitle>
                          <Text element="h3" size="large" weight="medium">
                            {item.title}
                          </Text>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                          <Copy>
                            {item.copy ?? (
                              <Text element="ul">
                                {item.items.map((itemCopy) => (
                                  <Text element="li" key={itemCopy}>
                                    {itemCopy}
                                  </Text>
                                ))}
                              </Text>
                            )}
                          </Copy>
                        </AccordionItemBody>
                      </AccordionItem>
                    ))}
                  </Accordion>
                }
                center
                figurePosition="right"
                size="xlarge"
                className={styles.product}>
                <Text align="center" element="figure">
                  <Container flush size="large">
                    <Box color="grey">
                      {React.cloneElement(productActiveItem.image, {
                        className: styles['product-accordion--screenshot'],
                      })}
                    </Box>
                  </Container>
                </Text>
              </MediaObject>
            </Row>
          </Container>
        </Row>
        <Container>
          <Title
            {...titleProps}
            subtext="Schedule time with our team."
            cta={
              <Buttons align="center" reset="mobile">
                <Button {...buttonProps} to={meetingUrl}>
                  Book time with our team
                </Button>
                <Button {...buttonProps} to={paths.REQUEST_DEMO} color="white">
                  Book a virtual demo with us
                </Button>
              </Buttons>
            }>
            Connect with us at {name}
          </Title>
        </Container>
      </View>
    </Layout>
  )
}
